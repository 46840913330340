import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { getPathKey, titlesByPathname } from 'app/appBar.component';
import { helpContentBySection } from './search.constants';
import SearchDrawer from './searchDrawer.component';
import SearchPagingBar from './searchPagingBar.component';
import Layout from '~common/components/Layout';
import ErrorBoundary from '~infra/errorBoundary.component.js';
import { common } from '~common/common.ducks';
import Results from '~search/results.component';
import { search, withSection } from '~search/search.ducks';
import SearchBar from '~search/searchBar.component';
import KeyListener from '~search/keyListener.component';

const onShowText = (index, onShowItem, history) => {
  history.push('/texts/browse');
  onShowItem(index);
};

const getMessage = (databasesInitialized, databases, results) => {
  if (databasesInitialized && !databases.length) {
    return (
      <Message>
        Sinulla ei ole käyttöoikeuksia tähän osioon. Kaikilla käyttäjillä on
        käyttöoikeus vähintään&nbsp;
        <a
          href={document
            .getElementById('app')
            .getAttribute('data-sivuarkisto-url')}
        >
          Sivut-osioon
        </a>
        .
      </Message>
    );
  }

  if (results.status === 'error') {
    return (
      <Message>
        Hetkinen... Jos mitään ei tapahdu, yritä hetken kuluttua uudelleen
        ja/tai päivitä sivu.
      </Message>
    );
  }

  // adf
  return null;
};

const SearchPage = ({
  history,
  section,
  itemComponent,
  openedItemComponent,
  databasesInitialized,
  databases,
  inputValues,
  criteria,
  criteriaOptions,
  filters,
  paging,
  results,
  drawerVisible,
  onUpdateCriteria,
  onUpdateFilter,
  onUpdateInputValue,
  onUpdatePaging,
  onSelectItem,
  onShowItem,
  onClear,
  onToggleDrawer,
}) => {
  const location = useLocation();
  const pathname = location.pathname;

  const brokenResults = results.totalCount > 0 && !results.items.length;

  const selectedDatabase = inputValues.database
    ? databases.find((db) => db.id === inputValues.database)
    : undefined;

  if (selectedDatabase) {
    const pathKey = getPathKey(pathname);
    const titleBase = titlesByPathname[pathKey];
    if (titleBase)
      document.title = titleBase.replace(
        '- HOLVI',
        `- ${selectedDatabase.title}`
      );
  }

  return (
    <ErrorBoundary>
      <KeyListener
        results={results}
        paging={paging}
        onUpdatePaging={onUpdatePaging}
        onSelectItem={onSelectItem}
      />
      <SearchDrawer
        key="drawer"
        section={section}
        drawerVisible={drawerVisible}
        databases={databases}
        inputValues={inputValues}
        onUpdateInputValue={onUpdateInputValue}
        criteria={criteria}
        criteriaOptions={criteriaOptions}
        onUpdateCriteria={onUpdateCriteria}
        filters={filters}
        onUpdateFilter={onUpdateFilter}
        onClear={onClear}
      />
      <StyledMain key="main" drawerVisible={drawerVisible}>
        <FixedWrapper drawerVisible={drawerVisible}>
          <SearchBarWrapper>
            <SearchBar
              inputValues={inputValues}
              onUpdateInputValue={onUpdateInputValue}
              onUpdateCriteria={onUpdateCriteria}
              onToggleDrawer={onToggleDrawer}
              drawerVisible={drawerVisible}
              color={
                /* Criteria could be checked in a prettier way, but requires
                   quite specific filtering */
                !databases.length ||
                criteria.database !== databases[0].id ||
                (!!criteria.magazines && criteria.magazines.length !== 0) ||
                (!!criteria.photographers &&
                  criteria.photographers.length !== 0) ||
                (!!criteria.startDate && criteria.startDate.length !== 0) ||
                (!!criteria.endDate && criteria.endDate.length !== 0) ||
                (!!filters.shape && filters.shape !== '-')
                  ? 'primary'
                  : 'default'
              }
            />
            <Layout flex={0.1}>
              <HelpButton>
                <a
                  href={helpContentBySection[section]}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Käyttöohjeet"
                >
                  <HelpIcon />
                </a>
              </HelpButton>
            </Layout>
          </SearchBarWrapper>
          {results.totalCount === -1 && results.status === 'fetching' && (
            <LinearProgress />
          )}
          {results.totalCount !== -1 && (
            <SearchPagingBar
              section={section}
              criteria={criteria}
              onUpdateCriteria={onUpdateCriteria}
              paging={paging}
              onUpdatePaging={onUpdatePaging}
              results={results}
              drawerVisible={drawerVisible}
            />
          )}
        </FixedWrapper>
        <Content>
          {getMessage(databasesInitialized, databases, results)}
          {results.totalCount !== -1 && results.status !== 'error' && (
            <ResultsWrapper>
              <div style={{ minHeight: '480px' }}>
                {results.status === 'fetching' && <LinearProgress />}
                {results.status !== 'fetching' && !brokenResults && (
                  <Results
                    criteria={criteria}
                    results={results}
                    paging={paging}
                    filters={filters}
                    onUpdatePaging={onUpdatePaging}
                    onSelectItem={onSelectItem}
                    onShowItem={(index) =>
                      onShowText(index, onShowItem, history)
                    }
                    itemComponent={itemComponent}
                    openedItemComponent={openedItemComponent}
                  />
                )}
                {results.status !== 'fetching' && brokenResults && (
                  <Message>
                    Tämä hakutuloksen sivu sisältää rikkinäistä tietoa, jota ei
                    näytetä. Vaihda sivua, kiitos.
                  </Message>
                )}
              </div>
            </ResultsWrapper>
          )}
        </Content>
      </StyledMain>
    </ErrorBoundary>
  );
};

const StyledMain = withTheme(styled.main`
  width: ${(props) => (props.drawerVisible ? 'calc(100% - 240px)' : '100%')};
  margin-left: ${(props) => (props.drawerVisible ? '0' : '-240px')};
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  position: relative;
  transition-property: margin;
  transition-duration: ${(props) =>
    props.drawerVisible
      ? props.theme.transitions.duration.enteringScreen
      : props.theme.transitions.duration.leavingScreen};
  transition-timing-function: ${(props) =>
    props.drawerVisible
      ? props.theme.transitions.easing.easeOut
      : props.theme.transitions.easing.sharp};

  @media print {
    margin: 0;
    width: 100%;
  }
`);

const Content = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;

  @media print {
    margin-top: 0;
  }
`;

const FixedWrapper = styled.div`
  z-index: 2;
  border-bottom: 1px solid #eee;

  @media print {
    display: none;
  }
`;

const ResultsWrapper = styled.div``;

const Message = styled.div`
  padding: 32px;
`;

const SearchBarWrapper = styled(Layout)`
  background-color: #eee;
  padding: 0px 32px;
  flex-wrap: wrap;
`;

const HelpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 100%;
  a {
    color: #0000008a;
  }
`;

export const withMapStateToProps = (
  section,
  itemComponent,
  openedItemComponent
) => {
  return (state) => {
    return {
      section,
      itemComponent,
      openedItemComponent,
      inputValues: state.search[section].inputValues,
      criteria: state.search[section].criteria,
      criteriaOptions: state.search[section].criteriaOptions,
      filters: state.search[section].filters,
      paging: state.search[section].paging,
      results: state.search[section].results,
      databasesInitialized: state.common.databasesBySection.initialized,
      databases: state.common.databasesBySection[section],
      drawerVisible: state.common.drawerVisible,
    };
  };
};

export const withMapDispatchToProps = (section) => {
  return (dispatch) => {
    const actionCreators = bindActionCreators(
      {
        onUpdateInputValue: search.updateInputValue,
        onUpdateCriteria: search.updateCriteria,
        onUpdateFilter: search.updateFilter,
        onUpdatePaging: search.updatePaging,
        onSelectItem: search.selectItem,
        onShowItem: search.showItem,
        onClear: search.clear,
        onToggleDrawer: common.toggleDrawer,
      },
      dispatch
    );

    return {
      // Add section parameter for all search actions
      onUpdateInputValue: withSection(
        section,
        actionCreators.onUpdateInputValue
      ),
      onUpdateCriteria: withSection(section, actionCreators.onUpdateCriteria),
      onUpdateFilter: withSection(section, actionCreators.onUpdateFilter),
      onUpdatePaging: withSection(section, actionCreators.onUpdatePaging),
      onSelectItem: withSection(section, actionCreators.onSelectItem),
      onShowItem: withSection(section, actionCreators.onShowItem),
      onClear: withSection(section, actionCreators.onClear),
      onToggleDrawer: actionCreators.onToggleDrawer,
    };
  };
};

export default withRouter(SearchPage);
